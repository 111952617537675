<template>
  <div class="auth-wrapper auth-v2" />
</template>

<script>
/* eslint-disable global-require */

export default {

  data() {
    return {

    }
  },
  computed: {

  },
  methods: {

  },
}
</script>
